import { Android } from '@mui/icons-material'
import React from 'react'
import AndroidImg from '../Assets/google-play.png';
import AppleImg from '../Assets/app-store.png'
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie';

const StoresDownload = () => {
  const { t, i18n } = useTranslation();
  const lng=cookies.get("i18next")|| "en";
  return (
    <section id="download">
      <div className="stores-section-wrapper">
          <h1 className="primary-heading"dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("have question in mind?")}</h1>
          <h1 className="third-heading"dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("download app")}</h1>
          < div className="store-buttons">
              <img src={AndroidImg} style={{marginRight: '200px'}} />
              <img src={AppleImg} alt="" />

          </div>

      </div>
    </section>
  )
}

export default StoresDownload