import React from 'react';
import ExploreImg from "../Assets/explore-image.png";
import CustomizeImg from "../Assets/customize-image.png";
import PayImg from "../Assets/pay-image.png";
import WorkBackground from "../Assets/coral-background.png";
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie';

const Work = () => {
  const { t, i18n } = useTranslation();
  const lng=cookies.get("i18next")|| "en";

    const workInfoData = [
      {
        image: ExploreImg,
        title: {
          en: "Explore & Select",
          ar: "استكشف واختر",
        },
        text: {
          en: "Browse various activities, trips, and tools tailored for every occasion, from family vacations to romantic honeymoons.",
          ar: "تصفح مجموعة متنوعة من الأنشطة والرحلات والأدوات المخصصة لكل مناسبة، من عطلات العائلة إلى شهر العسل الرومانسي"
        },
      },
      {
        image: CustomizeImg,
        title: {
          en: "Customize Your Experience",
          ar: "خصص تجربتك",
        },
        text: {
          en: "Fine-tune your reservation by picking specific dates, times, and additional options for a personalized experience.",
          ar: "قم بتخصيص حجزك من خلال اختيار تواريخ وأوقات وخيارات إضافية لتجربة شخصية"
        },
      },
      {
        image: PayImg,
        title: {
          en: "Pay & Enjoy",
          ar: "ادفع واستمتع",
        },
        text: {
          en: "Complete your booking with secure online payment and get ready for an adventure like no other!",
          ar: "أكمل حجزك من خلال الدفع الآمن عبر الإنترنت واستعد لمغامرة لا مثيل لها"
        },
      },
      ];
    
  return (
    <div className='work-section-container'>

      <div className="work-section-wrapper">
        <div className="work-section-top">
          <p className="primary-subheading-work"dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("work")}</p>
          <h1 className="primary-heading"dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("how it works")}</h1>
          <p className="primary-text"dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("how it works body")}</p>
        </div>
        <div className="work-section-bottom">
          {workInfoData.map((data) => (
            <div className="work-section-info" key={data.title.en} >            
              <div className="info-boxes-img-container">
                <img src={data.image} alt="" />
              </div>
              <h2>{data.title[i18n.language]}</h2> {/* Access title based on the current language */}
              <p>{data.text[i18n.language]}</p>   {/* Access text based on the current language */}
            </div>
          ))}
        </div>
      </div>

      <div className="work-background-image-container">
        <img src={WorkBackground} style={{ width: '300px', height: '850px' }} />
      </div>
  </div>
  )
}

export default Work