import React, { useState } from 'react'
import Logo from "../Assets/wlmma final logo 2.0.png";
import {HiOutlineBars3} from "react-icons/hi2";
import{
    Box,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
} from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon  from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie';

const Navbar = () => {

  const { t, i18n } = useTranslation();
  const lng = cookies.get("i18next") || "en"; 

// control devices
 const [openMenu, setOpenMenu]= useState (false)
 const menuOptions= [
    {
        text: t("home"),
        icon: <HomeIcon />,
      },
      {
        text: t("about nav"),
        icon: <InfoIcon />,
        link: "#about"
      },
      {
        text: t("testimonials nav"),
        icon: <CommentRoundedIcon />,
        link:"#testimonials"
      },
      {
        text: t("contact nav"),
        icon: <PhoneRoundedIcon />,
        link: "#contact"
      },

    ];

    const scrollToSection = (id) => {
      const element = document.querySelector(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
    
    return(
        <nav>
        <div className="nav-logo-container">
           <img src={Logo} alt="Logo" style={{ width: '300px', height: 'auto' }} />
        </div>

        <div className="navbar-links-container">
            <a href="">{t("home")}</a>
            <a href="#about">{t("about nav")}</a>   
            <a href="#testimonials">{t("testimonials nav")}</a>   
            <a href="#contact">{t("contact nav")}</a> 
            <button className="primary-button" onClick={() => scrollToSection('#download')}>{t("download now")}</button>


        </div>

        <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
       </div>


       <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>

    </nav>
    );
};

export default Navbar