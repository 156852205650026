import './App.css';
import Home from './Components/Home';
import About from './Components/About';
import Work from './Components/Work';
import CallAction from './Components/CallAction';
import Testimonials from './Components/Testimonials';
import StoresDownload from './Components/StoresDownload';
import Footer from './Components/Footer';


function App() {

  return (
    <div className="App">
      <Home/>
      <About/>
      <Work/>
      <CallAction/>
      <Testimonials/>
      <StoresDownload/>
      <Footer/>
    </div>
  );
}

export default App;
