import React from 'react'
import TestimonialsBackground from "../Assets/yellow-background.png";
import ProfilePic from "../Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie';


const Testimonials = () => {
  const { t, i18n } = useTranslation();
  const lng=cookies.get("i18next")|| "en";

  return (
    <section id="testimonials">
      <div className="testimonials-section-container">

          <div className="testimonials-background-image-container">
          <img src={TestimonialsBackground} style={{ width: '300px', height: '850px' }} />
          </div>

          <div className="testimonials-section-wrapper">
              <div className="work-section-top">
                  <p className="primary-subheading"dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("testimonial")}</p>
                  <h1 className="primary-heading"dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("what are they saying")}</h1>

              </div>
              <div className="testimonial-section-bottom">
                  <img src={ProfilePic} alt="" />
                  <p dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("what are they saying body")}</p>
                  <div className="testimonials-stars-container">
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  </div>
                  <h2>John Doe</h2>
              </div>
          </div>

      </div>
    </section>
  )
}

export default Testimonials