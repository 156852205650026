import React from "react";
import Logo from "../Assets/wlmma final logo 2.0 BW.png";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import BackgroundImg from "../Assets/footer-background-2.png";
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie';


const Footer = () => {
  const { t, i18n } = useTranslation();
  const lng=cookies.get("i18next")|| "en";
  return (
    <section id="contact">
      <div className="footer-wrapper">

        {/*<div className="footer-banner-container">
          <div className="footer-bannerImage-container">
            <img src={BackgroundImg} />
          </div>
        </div>*/}

        <div className="footer-section-one">
          <div className="footer-logo-container">
            <img src={Logo} alt="" />
          </div>
        </div>

        <div className="footer-section-two">

          <div className="footer-section-columns">
              <p className="primary-subheading-footer"dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("contact us")}</p>

              <div className="footer-icons">
              <BsTwitter />
              <SiLinkedin />
              <BsYoutube />
              <FaFacebookF />
              </div>

              <p className="primary-subheading"></p>
              <span>244-5333-7783</span>
              <span>contact@wlmma.com</span>
          </div>
          {/*
          <div className="footer-section-columns">
            <span>Terms & Conditions</span>
            <span>Privacy Policy</span>
          </div>
          */}
        </div>
      </div>
    </section>
  );
};

export default Footer;