import React from 'react'
import CallActionImg from "../Assets/callaction-image-woman.png"
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie';

const CallAction = () => {
  const { t, i18n } = useTranslation();
  const lng=cookies.get("i18next")|| "en";

  return (
    <div className="callaction-section-container">

      <div className="callaction-section-image-container">
        <img src={CallActionImg} alt="" />
      </div>

      <div className="callaction-text-container">
        <h1 className="callaction-heading"dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("call to action")}</h1>

        <button className="callaction-button"dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("download button")}{" "}
        </button>
      </div>

   </div>
  )
}

export default CallAction