import React from 'react'
import AboutBackground from "../Assets/yellow-background.png"
import AboutBackgroundImage from "../Assets/about-image-man.png"
import ReservationsIcon from "../Assets/reservations-icon.png"
import ActivitiesIcon from "../Assets/activities-icon.png"
import PaymentIcon from "../Assets/payment-icon.png"
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie';



const About = () => {
  const { t, i18n } = useTranslation();
  const lng=cookies.get("i18next")|| "en";

  return (
    <section id="about">
      <div className="about-section-container">

        <div className="about-background-image-container">
          <img src={AboutBackground} style={{ width: '300px', height: '800px' }} />
        </div>

        


        <div className="about-section-text-container">


          <p className="primary-subheading" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("about")}</p>
          <h1 className="primary-heading"dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("why choose us")} </h1>

          <div className="about-subcontainer" style={{ paddingBottom: '30px', paddingTop: '40px', display: i18n.language === 'ar' ? 'flex': 'block', flexDirection: i18n.language === 'ar' ? 'row-reverse' : 'row', alignItems: 'center',alignSelf: 'flex-end' }}>
            <div className="icons-image-container">
                  <img src={ReservationsIcon} alt="" />
            </div> 
            <div className="subtext-container" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} style={{ paddingRight: '20px' }}>
              <p className="secondary-subheading">{t("tailored reservations")}</p>
              <p className="about-primary-text">{t("tailored reservations body")}</p>
            </div>

          </div>


          <div className="about-subcontainer" style={{paddingBottom: '30px',display: i18n.language === 'ar' ? 'flex': 'block', flexDirection: i18n.language === 'ar' ? 'row-reverse' : 'row', alignItems: 'center',alignSelf: 'flex-end'}}>

              <div className="icons-image-container">
                  <img src={ActivitiesIcon} alt="" />
              </div>

              <div className="subtext-container"dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} style={{ paddingRight: '20px' }}>
                  <p className="secondary-subheading" >{t("diverse activities & services")}</p>
                  <p className="about-primary-text"> {t("diverse activities & services body")} </p>
              </div>

          </div>


          <div className="about-subcontainer" style={{paddingBottom: '30px',display: i18n.language === 'ar' ? 'flex': 'block', flexDirection: i18n.language === 'ar' ? 'row-reverse' : 'row', alignItems: 'center', alignSelf: 'flex-end'}}>
            
              <div className="icons-image-container">
                  <img src={PaymentIcon} alt="" />
              </div>

              <div className="subtext-container"dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} style={{ paddingRight: '20px' }}>
                  <p className="secondary-subheading"> {t("seamless online payment")}</p>
                  <p className="about-primary-text"> {t("seamless online payment body")}</p>
              </div>
          </div>

        </div>
        <div className="about-section-image-container">
          <img src={AboutBackgroundImage} alt="" />
        </div>
      </div> 
    </section>
  )
}

export default About