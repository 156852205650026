import Navbar from "./Navbar";
import BannerBackground from "../Assets/Coral banner bg.png";
import BannerImage from "../Assets//banner-image-woman-2.png";
import TranslateIcon from "../Assets//translate-icon.png"
import { FiArrowRight,  FiArrowLeft } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie';

const Home = () => {
  const { t, i18n } = useTranslation();
  const lng=cookies.get("i18next")|| "en";
  /*useEffect(()=>{
    window.document.dir=i18n.dir();
  },[i18n.language]);*/

  return (
    <div className="home-container">
      <div className="translate-box">  
       {i18n.language=='en'&& <button onClick={()=>{i18n.changeLanguage('ar');}} className="translate-button">االغه العربيه</button>}
       {i18n.language=='ar'&&<button onClick={()=>{i18n.changeLanguage('en');}} className="translate-button">English</button>}
      <img src={TranslateIcon} alt="" />
      </div>
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
      </div>

      <div className="home-section">
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>{t("title")}</h1>
          <p className="primary-text" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}> {t("description")}</p>
          <button className="secondary-button">
             {i18n.language === 'ar' ? <FiArrowLeft />:null}{t("download button")} {i18n.language === 'en' ? <FiArrowRight /> : null}{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
